var _jsxFileName = "/Users/dimitribenz/frontend/packages/frontend/ui/src/components/Headline/index.js";
import * as React from 'react';
import styles from './style.module.scss';
import { withThemeVariant } from '../../provider/ThemeProvider/index';

/**
 * A headline component providing styled h1, h2, h3 or h4 headline elements
 *
 * @param	{1|2|3|4}	size			The headlines hierarchy (h1 to h4)
 * @param	{string}	color			A css conform hex/rgb/rgba color code
 * @param	{boolean}	noMargin	Wether or not to render the componentn with margin
 * @param	{boolean}	center		Wether or not to center the content
 * @param	{string}	textAlign	The headings text align
 * @param	{boolean}	pseudo	  Wether or not to render as a real HTML heading element
 * @param	{content}	children	The headlines content
 **/
var Headline = function Headline(_ref) {
  var size = _ref.size,
      children = _ref.children,
      color = _ref.color,
      _ref$noMargin = _ref.noMargin,
      noMargin = _ref$noMargin === void 0 ? false : _ref$noMargin,
      _ref$center = _ref.center,
      center = _ref$center === void 0 ? false : _ref$center,
      _ref$textAlign = _ref.textAlign,
      textAlign = _ref$textAlign === void 0 ? 'left' : _ref$textAlign,
      _ref$pseudo = _ref.pseudo,
      pseudo = _ref$pseudo === void 0 ? false : _ref$pseudo,
      globalThemeVariant = _ref.globalThemeVariant,
      globalThemeSubVariant = _ref.globalThemeSubVariant,
      className = _ref.className,
      displayAs = _ref.displayAs;
  // "Legacy" support
  textAlign = center ? 'center' : textAlign;
  var combinedClassName = "\n  ".concat(styles['heading' + (displayAs || size)], " ").concat(styles[globalThemeVariant], " ").concat(styles[globalThemeSubVariant], " ").concat(noMargin && styles.noMargin, " ").concat(className, "\n    ");

  var Base = function Base(props) {
    return pseudo ? React.createElement("div", {
      className: combinedClassName,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 56
      },
      __self: this
    }, children) : size === 1 ? React.createElement("h1", Object.assign({
      className: combinedClassName
    }, props, {
      __source: {
        fileName: _jsxFileName,
        lineNumber: 58
      },
      __self: this
    }), children) : size === 2 ? React.createElement("h2", Object.assign({
      className: combinedClassName
    }, props, {
      __source: {
        fileName: _jsxFileName,
        lineNumber: 62
      },
      __self: this
    }), children) : size === 3 ? React.createElement("h3", Object.assign({
      className: combinedClassName
    }, props, {
      __source: {
        fileName: _jsxFileName,
        lineNumber: 66
      },
      __self: this
    }), children) : size === 4 ? React.createElement("h4", Object.assign({
      className: combinedClassName
    }, props, {
      __source: {
        fileName: _jsxFileName,
        lineNumber: 70
      },
      __self: this
    }), children) : size === 5 ? React.createElement("h5", Object.assign({
      className: combinedClassName
    }, props, {
      __source: {
        fileName: _jsxFileName,
        lineNumber: 74
      },
      __self: this
    }), children) : React.createElement("div", Object.assign({
      className: combinedClassName
    }, props, {
      __source: {
        fileName: _jsxFileName,
        lineNumber: 78
      },
      __self: this
    }), children);
  };

  return React.createElement(Base, {
    style: {
      color: color,
      textAlign: textAlign
    },
    __source: {
      fileName: _jsxFileName,
      lineNumber: 83
    },
    __self: this
  }, children);
};

export default React.memo(withThemeVariant(Headline), function (prev, next) {
  return prev.children === next.children;
});