import _defineProperty from "/Users/dimitribenz/frontend/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
var _jsxFileName = "/Users/dimitribenz/frontend/packages/frontend/ui/src/components/Text/index.js";
import React from 'react';
import styles from './style.module.scss';
import cn from 'classnames';
import { withThemeVariant } from '../../provider/ThemeProvider/index';

/**
 * A generic Text component
 *
 **/
export var Section = function Section(_ref) {
  var _cn;

  var _ref$children = _ref.children,
      children = _ref$children === void 0 ? '' : _ref$children,
      _ref$globalThemeVaria = _ref.globalThemeVariant,
      globalThemeVariant = _ref$globalThemeVaria === void 0 ? 'pc' : _ref$globalThemeVaria,
      globalThemeSubVariant = _ref.globalThemeSubVariant,
      _ref$className = _ref.className,
      className = _ref$className === void 0 ? false : _ref$className,
      _ref$align = _ref.align,
      align = _ref$align === void 0 ? 'left' : _ref$align,
      _ref$bold = _ref.bold,
      bold = _ref$bold === void 0 ? false : _ref$bold;
  return React.createElement("p", {
    className: cn(styles[globalThemeVariant], (_cn = {}, _defineProperty(_cn, className, className), _defineProperty(_cn, styles.bold, bold), _defineProperty(_cn, styles[globalThemeSubVariant], globalThemeSubVariant), _cn)),
    style: {
      textAlign: align
    },
    __source: {
      fileName: _jsxFileName,
      lineNumber: 25
    },
    __self: this
  }, children);
};
export default withThemeVariant(React.memo(Section));