var _jsxFileName = "/Users/dimitribenz/frontend/packages/frontend/ui/src/components/Link/index.js";
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

/*
 * A basic link component, that can be used as an internal router link or an eternal anchor
 * @param {string}    to        The URL / Path to navigate to
 * @param {boolean}   external  Wether or not to open in a new window
 * @param {Children}  children  The react children
 * @param {noFollow}  SEO,    if noFollow===true then rel='nofollow'
 */
var Link = function Link(_ref) {
  var _ref$to = _ref.to,
      to = _ref$to === void 0 ? '' : _ref$to,
      _ref$title = _ref.title,
      title = _ref$title === void 0 ? '' : _ref$title,
      children = _ref.children,
      _ref$external = _ref.external,
      external = _ref$external === void 0 ? false : _ref$external,
      _ref$anchor = _ref.anchor,
      anchor = _ref$anchor === void 0 ? false : _ref$anchor,
      _ref$noFollow = _ref.noFollow,
      noFollow = _ref$noFollow === void 0 ? false : _ref$noFollow,
      className = _ref.className,
      id = _ref.id,
      _ref$onClick = _ref.onClick,
      onClick = _ref$onClick === void 0 ? function () {} : _ref$onClick;
  var relTag = 'follow';

  if (noFollow) {
    relTag = 'nofollow';
  }

  if (external) {
    relTag = relTag + ' noopener noreferrer';
  }

  if (anchor) {
    return React.createElement(HashLink, {
      rel: relTag,
      className: className,
      to: to,
      title: title,
      onClick: onClick,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 45
      },
      __self: this
    }, children);
  }

  return to && (to.startsWith('http') || to.startsWith('//')) || external ? React.createElement("a", {
    href: to,
    target: external ? '_blank' : '_self',
    rel: relTag,
    className: className,
    id: id,
    title: title,
    onClick: onClick,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 58
    },
    __self: this
  }, children) : React.createElement(RouterLink, {
    rel: relTag,
    className: className,
    title: title,
    to: to,
    id: id,
    onClick: onClick,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 70
    },
    __self: this
  }, children);
};

export default Link;