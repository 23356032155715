import _defineProperty from "/Users/dimitribenz/frontend/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
var _jsxFileName = "/Users/dimitribenz/frontend/packages/frontend/ui/src/components/Button/index.js";
import React from 'react';
import cn from 'classnames';
import styles from './style.module.scss';
import Link from '../Link';

/**
 * The main button component
 * @param {Children}				children    The Buttons Text
 * @param {void}				    onClick	    On click event
 * @param {string}          link 		    Destination if button is an internal link
 * @param {boolean}         external    Indicates an external link
 * @param {buttonColors}    color 	    The color style of the button
 * @param {buttonVariants}  variant     The buttons variant
 * @param {buttonSizes}	    size        The size of the buttton
 * @param {boolean}	        fullWidth   wether or not to display the button fullwidth
 * @param {string}	        className   wether or not to display a extra class
 * @param {string}          anchor      A section anchor to navigate to
 * @param {string}          type        A type for the button
 * @param {boolean}         disabled    The button disabled attribute
 * @param {string}          title 		  Title text if button is an internal link
 **/
export var Button = function Button(_ref) {
  var _cn;

  var children = _ref.children,
      onClick = _ref.onClick,
      link = _ref.link,
      _ref$external = _ref.external,
      external = _ref$external === void 0 ? false : _ref$external,
      _ref$anchor = _ref.anchor,
      anchor = _ref$anchor === void 0 ? false : _ref$anchor,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? 'default' : _ref$color,
      _ref$variant = _ref.variant,
      variant = _ref$variant === void 0 ? 'raised' : _ref$variant,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? 'medium' : _ref$size,
      _ref$fullWidth = _ref.fullWidth,
      fullWidth = _ref$fullWidth === void 0 ? false : _ref$fullWidth,
      className = _ref.className,
      type = _ref.type,
      htmlType = _ref.htmlType,
      id = _ref.id,
      _ref$title = _ref.title,
      title = _ref$title === void 0 ? '' : _ref$title,
      _ref$disabled = _ref.disabled,
      disabled = _ref$disabled === void 0 ? false : _ref$disabled;
  var classes = cn(styles[variant], styles[color], styles[size], (_cn = {}, _defineProperty(_cn, styles.fullWidth, fullWidth === true), _defineProperty(_cn, styles['fullWidth-xs'], fullWidth === 'xs'), _defineProperty(_cn, styles['fullWidth-sm'], fullWidth === 'sm'), _defineProperty(_cn, styles['fullWidth-md'], fullWidth === 'md'), _defineProperty(_cn, styles['fullWidth-lg'], fullWidth === 'lg'), _defineProperty(_cn, styles['fullWidth-xl'], fullWidth === 'xl'), _defineProperty(_cn, className, className), _cn));
  return link || anchor !== false ? React.createElement(Link, {
    className: cn(styles.silentLink, classes),
    to: "".concat(link ? link : '').concat(anchor ? anchor : ''),
    external: external,
    anchor: anchor !== false,
    id: id,
    title: title,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 82
    },
    __self: this
  }, children) : React.createElement("button", {
    id: id,
    className: classes,
    onClick: onClick,
    type: type || htmlType ? type || htmlType : 'button',
    disabled: disabled,
    "aria-label": "button",
    title: title,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 93
    },
    __self: this
  }, children);
};
export default Button;