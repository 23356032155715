import _slicedToArray from "/Users/dimitribenz/frontend/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
var _jsxFileName = "/Users/dimitribenz/frontend/packages/frontend/ui/src/components/PageSelection/index.js";
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Row, Col } from 'reactstrap';
import Headline from '@jobmatchme/ui/es/components/Headline';
import Button from '@jobmatchme/ui/es/components/Button';
import { FormattedMessage } from 'react-intl';
import styles from './style.module.scss';

var PageSelection = function PageSelection(_ref) {
  var bcLink = _ref.bcLink,
      pcLink = _ref.pcLink;

  var _useState = useState('left'),
      _useState2 = _slicedToArray(_useState, 2),
      mouseoverState = _useState2[0],
      setMouseoverState = _useState2[1];

  var _useState3 = useState(),
      _useState4 = _slicedToArray(_useState3, 2),
      sideRatioState = _useState4[0],
      setSideRatioState = _useState4[1];

  var leftSelector = useRef();
  var rightSelector = useRef();
  var handleOverLeft = useCallback(function (event) {
    setTimeout(function () {
      if (mouseoverState !== 'left') {
        setMouseoverState('left');
      }
    }, 50);
  });
  var handleOverRight = useCallback(function (event) {
    setTimeout(function () {
      if (mouseoverState !== 'right') {
        setMouseoverState('right');
      }
    }, 50);
  });
  /*
   ** Get Window Aspect Ratio from Selection side.
   ** Each side occupies 50% of the screen.
   ** This value is important for the correct positioning of the background image.
   */

  var isWidthBiggerAsHeight = function isWidthBiggerAsHeight() {
    var thisLHeight = leftSelector.current.clientHeight;
    var thisLWidth = leftSelector.current.clientWidth;
    var thisRHeight = rightSelector.current.clientHeight;
    var thisRWidth = rightSelector.current.clientWidth;

    if (mouseoverState !== 'left') {
      return thisLWidth > thisLHeight ? 1 : 0;
    } else {
      return thisRWidth > thisRHeight ? 1 : 0;
    }
  };

  var setRatioState = function setRatioState() {
    setTimeout(function () {
      if (sideRatioState !== isWidthBiggerAsHeight()) {
        setSideRatioState(isWidthBiggerAsHeight());
      }
    }, 50);
  };

  var handleResizeWindow = useCallback(function (event) {
    setRatioState();
  });

  var toggleCSS = function toggleCSS(side, mouseoverState, sideRatioState) {
    /*
     ** Depending on the selected side
     ** the class will be changed.
     */
    if (sideRatioState < 1) {
      if (side === 'left') {
        return mouseoverState === 'left' ? " ".concat(styles.pcSelectorActive) : " ".concat(styles.pcSelector);
      } else {
        return mouseoverState === 'right' ? " ".concat(styles.bcSelectorActive) : " ".concat(styles.bcSelector);
      }
    } else {
      if (side === 'left') {
        return mouseoverState === 'left' ? " ".concat(styles.pcSelectorActiveShort) : " ".concat(styles.pcSelectorShort);
      } else {
        return mouseoverState === 'right' ? " ".concat(styles.bcSelectorActiveShort) : " ".concat(styles.bcSelectorShort);
      }
    }
  };

  useEffect(function () {
    leftSelector.current.addEventListener('mouseover', handleOverLeft);
    rightSelector.current.addEventListener('mouseover', handleOverRight); //rightSelector.current.addEventListener('mouseleave', handleOverLeft)

    window.addEventListener('resize', handleResizeWindow);
    setRatioState();
    return function () {
      leftSelector.current.removeEventListener('mouseover', handleOverLeft);
      rightSelector.current.removeEventListener('mouseover', handleOverRight); // rightSelector.current.removeEventListener('mouseleave', handleOverLeft)

      window.removeEventListener('resize', handleResizeWindow);
    };
  }, [handleOverLeft, handleOverRight, handleResizeWindow]);
  return React.createElement("section", {
    className: styles.PageSelectionMenu,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 107
    },
    __self: this
  }, React.createElement(Row, {
    __source: {
      fileName: _jsxFileName,
      lineNumber: 108
    },
    __self: this
  }, React.createElement(Col, {
    md: 6,
    className: toggleCSS('left', mouseoverState, sideRatioState),
    __source: {
      fileName: _jsxFileName,
      lineNumber: 109
    },
    __self: this
  }, React.createElement("div", {
    key: "leftSelector",
    ref: leftSelector,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 113
    },
    __self: this
  }, React.createElement(Headline, {
    size: 2,
    color: "white",
    className: styles.selectionMessage,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 114
    },
    __self: this
  }, React.createElement(FormattedMessage, {
    id: "app.ui.pageselection",
    defaultMessage: "ICH SUCHE EINEN JOB ALS KRAFTFAHRER",
    __source: {
      fileName: _jsxFileName,
      lineNumber: 119
    },
    __self: this
  })), React.createElement(Button, {
    id: "cta-pc-landingpage",
    color: "light",
    size: "large",
    variant: "raised",
    link: pcLink,
    title: "LKW Job",
    className: styles.BlueButton,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 124
    },
    __self: this
  }, React.createElement(FormattedMessage, {
    id: "app.ui.pageselection.calltoaction1",
    defaultMessage: "JOB ALS LKW-FAHRER FINDEN",
    __source: {
      fileName: _jsxFileName,
      lineNumber: 133
    },
    __self: this
  })))), React.createElement(Col, {
    md: 6,
    className: toggleCSS('right', mouseoverState, sideRatioState),
    __source: {
      fileName: _jsxFileName,
      lineNumber: 141
    },
    __self: this
  }, React.createElement("div", {
    key: "rightSelector",
    ref: rightSelector,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 145
    },
    __self: this
  }, React.createElement(Headline, {
    size: 2,
    color: "white",
    className: styles.selectionMessage,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 146
    },
    __self: this
  }, React.createElement(FormattedMessage, {
    id: "app.ui.pageselection",
    defaultMessage: "ICH SUCHE KRAFTFAHRER F\xDCR MEIN UNTERNEHMEN",
    __source: {
      fileName: _jsxFileName,
      lineNumber: 151
    },
    __self: this
  })), React.createElement(Button, {
    id: "cta-pc-landingpage",
    color: "light",
    size: "large",
    variant: "raised",
    link: bcLink,
    title: "Fahrer finden",
    className: styles.DarkBlueButton,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 156
    },
    __self: this
  }, React.createElement(FormattedMessage, {
    id: "app.ui.pageselection.calltoaction2",
    defaultMessage: "LKW-FAHRER FINDEN",
    __source: {
      fileName: _jsxFileName,
      lineNumber: 165
    },
    __self: this
  }))))));
};

export default React.memo(PageSelection);