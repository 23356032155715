import React from 'react'
import '@jobmatchme/ui/src/styles/global.scss'

import Headline from '@jobmatchme/ui/es/components/Headline'
import PageSelection from '@jobmatchme/ui/es/components/PageSelection'
import { FormattedMessage } from 'react-intl'
import { Row, Col } from 'reactstrap'
import Text from '@jobmatchme/ui/es/components/Text'
import Button from '@jobmatchme/ui/es/components/Button'

import truckjobs_logo from './Images/truckjobs_logo.svg'
import eurotransport_logo from './Images/eurotransport_logo.svg'
import fa_logo from './Images/fa_logo.svg'
import fern_logo from './Images/fern_logo.svg'
import lao_logo from './Images/lao_logo.svg'
import tele_logo from './Images/tele_logo.svg'
import trans_logo from './Images/trans_logo.svg'
import werk_logo from './Images/werk_logo.svg'

import styles from './style.module.scss'

const Eurotransport = () => (
  <div className={styles.pageContent}>
    <header className={styles.header}>
      {
        <nav className={styles.firstNav}>
          <ul>
            <li>
              <a
                href="https://www.eurotransport.de/transaktuell-429965.html"
                title="Trans Aktuell"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={trans_logo}
                  alt="Trans Aktuell"
                  className={styles.trans_logo}
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.eurotransport.de/lastauto-429969.html"
                title="Lastauto Omnibus"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={lao_logo}
                  className={styles.lao_logo}
                  alt="Lastauto Omnibus"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.eurotransport.de/fernfahrer-429961.html"
                title="Fernfahrer"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={fern_logo}
                  className={styles.fern_logo}
                  alt="Fernfahrer"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.eurotransport.de/werkstatt-aktuell-6576917.html"
                title="Werkstatt aktuell"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={werk_logo}
                  className={styles.werk_logo}
                  alt="Werkstatt aktuell"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.eurotransport.de/teletraffic-10412318.html"
                title="Teletraffic"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={tele_logo}
                  className={styles.tele_logo}
                  alt="Teletraffic"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.firmenauto.de/"
                title="Firmenauto"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={fa_logo}
                  className={styles.fa_logo}
                  alt="Firmenauto"
                />
              </a>
            </li>
          </ul>
          <span>
            <a
              href="https://www.etm.de/mediadaten/"
              title="Mediadaten"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mediadaten
            </a>
            <a
              href="https://www.etm.de/"
              title="Verlag"
              target="_blank"
              rel="noopener noreferrer"
            >
              Verlag
            </a>
          </span>
        </nav>
      }
      <section>
        <div className={styles.logoBlock}>
          <Row>
            <Col lg={6} md={6} className={styles.firmLogo}>
              <a href="/">
                <img
                  className={styles.eurotransport_logo}
                  src={eurotransport_logo}
                  alt=""
                />
              </a>
            </Col>
            <Col lg={6} md={6} className={styles.cooperation}>
              <FormattedMessage
                id="app.eurotransport.pageselection.cooperation"
                defaultMessage="In Kooperation mit "
              />
              <a
                href="https://www.truck-jobs.com/?utm_source=eurotransport&utm_campaign=partner&utm_medium=logo"
                title="LKW Jobs"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={truckjobs_logo}
                  className={styles.truckjobs_logo}
                  alt="TruckJobs: Fahrer Job finden"
                />
              </a>
            </Col>
          </Row>
        </div>
      </section>
    </header>

    <PageSelection
      pcLink={
        'https://www.truck-jobs.com/de/fahrer/jobsuche/?utm_source=eurotransport&utm_campaign=partner'
      }
      bcLink={'https://www.truck-jobs.com/de/arbeitgeber/?utm_source=eurotransport&utm_campaign=partner&utm_medium=bc'}
    />

    <section noMargin noPadding className={styles.textSection}>
      <div className={styles.PageSelectionText}>
        <Row>
          <Col md={6} className={styles.pcContent}>
            <div className={styles.contentCollumn}>
              <Row className={styles.textHeader}>
                <Headline size={3} className={styles.selectionMessage}>
                  <FormattedMessage
                    id="app.eurotransport.pageselection.textleft"
                    defaultMessage="Suchst du einen neuen Job als LKW Fahrer?"
                  />
                </Headline>
              </Row>

              <Row>
                <Text className={styles.text}>
                  <FormattedMessage
                    id="app.eurotransport.pageselection.textleft1"
                    defaultMessage="Mit TruckJobs findest du den passenden Arbeitgeber! Dazu stellen
                  wir dir ein paar Fragen, um heraus zu finden, wer zu dir passt!"
                  />
                </Text>
                <Text className={styles.text}>
                  <FormattedMessage
                    id="app.eurotransport.pageselection.textleft2"
                    defaultMessage="Du entscheidest danach, wer dich kontaktieren soll. Und das mit
                  nur einem Klick."
                  />
                </Text>
                <Text className={styles.text}>
                  <FormattedMessage
                    id="app.eurotransport.pageselection.textleft3"
                    defaultMessage="TruckJobs ist für dich völlig kostenlos. Du brauchst keinen
                  Lebenslauf und kein Anschreiben. Du kannst deine Daten
                  selbstverständlich löschen. TruckJobs ist super einfach und
                  absolut transparent."
                  />
                </Text>
                <div className={styles.leftButton}>
                  <Button
                    id="cta-pc-landingpage"
                    color="light"
                    size="large"
                    variant="raised"
                    link="https://www.truck-jobs.com/de/fahrer/jobsuche/?utm_source=eurotransport&utm_campaign=partner&utm_medium=pc"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="LKW Job"
                    className={styles.BlueButton}
                  >
                    <FormattedMessage
                      id="app.eurotransport.pageselection.calltoaction1"
                      defaultMessage="LOS GEHT’S!"
                    />
                  </Button>
                </div>
              </Row>
            </div>
          </Col>

          <Col md={6} className={styles.bcContent}>
            <div className={styles.contentCollumn}>
              <Row className={styles.textHeader}>
                <Headline size={3} className={styles.selectionMessage}>
                  <FormattedMessage
                    id="app.eurotransport.pageselection.textright"
                    defaultMessage="Truckjobs bringt Lkw-Fahrer mit passenden Arbeitgebern zusammen"
                  />
                </Headline>
              </Row>
              <Row>
                <span className={styles.firstParagraph}>
                  <Text className={styles.text}>
                    <FormattedMessage
                      className={styles.firstParagraph}
                      id="app.eurotransport.pageselection.textright1"
                      defaultMessage="Mit TruckJobs erhalten Arbeitgeber endlich wieder Bewerbungen von
                aktuell suchenden LKW-Fahrern. Durch innovatives Job-Matching
                werden die Qualifikationen der Fahrer mit den Anforderungen des
                Unternehmens abgeglichen. "
                    />{' '}
                  </Text>
                </span>
                <Text className={styles.text}>
                  <FormattedMessage
                    id="app.eurotransport.pageselection.textright2"
                    defaultMessage="Seien Sie als Unternehmer dort, wo
                monatlich über 6.000 Fahrer einen Job suchen. "
                  />
                </Text>
                <Text className={styles.text}>
                  <FormattedMessage
                    id="app.eurotransport.pageselection.textright3"
                    defaultMessage="Dashboard zum Bewerbermanagement können Sie jederzeit auf die
                Fahrerprofile zugreifen und die Kandidaten direkt kontaktieren."
                  />
                </Text>
                <div className={styles.rightButton}>
                  <Button
                    id="cta-pc-landingpage"
                    color="dark"
                    size="large"
                    variant="raised"
                    link="https://www.truck-jobs.com/de/arbeitgeber/?utm_source=eurotransport&utm_campaign=partner&utm_medium=bc"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Fahrer finden"
                    className={styles.DarkBlueButton}
                  >
                    <FormattedMessage
                      id="app.eurotransport.pageselection.calltoaction2"
                      defaultMessage="KOSTENLOS TESTEN"
                    />
                  </Button>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </div>

      <iframe
        src="https://www.eurotransport.de/layout/footerJobs/activateNode/19094"
        style={{ width: '100vw', height:'360px', backgroundColor: '#eee', border: 'none' }}
        border="0"
        height="360"
        title="Eurotransport footer"
      ></iframe>
    </section>
  </div>
)

export default React.memo(Eurotransport)
